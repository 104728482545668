<template>
  <!-- the overall card needs a width or it will disappear when a too-large-list item is expanded!-->

  <v-card class="mb-3 pt-3 patientDrawer" color="transparent" tile flat>
    <v-card color="transparent" flat class="pb-4 px-3">
      <v-row no-gutters class="font-weight-light text-h5">
        <span>{{ patient.firstName }} {{ patient.lastName }}</span>
      </v-row>
      <v-row class="font-weight-regular text-subtitle-2 text--secondary" no-gutters>
        <span>{{ patientSubtitleLine }}</span>
      </v-row>
    </v-card>

    <patient-action-bar :patient="patient" />

    <v-slide-y-transition>
      <div>
        <v-card-text v-show="patientInfoSummary != ''" class="font-weight-light text-body-1 px-3 pt-4 mt-0 pb-1 mb-0">{{
          patientInfoSummary
        }}</v-card-text>

        <v-list class="pl-0">
          <v-list-group
            class="pl-0"
            v-for="item in summaryItems"
            :key="item.title"
            v-model="item.active"
            no-action
            :append-icon="item.icon"
            @click="clickedListGroup(item)"
          >
            <template v-slot:activator>
              <v-list-item class="px-0">
                <v-list-item-icon class="mr-2 ml-0">
                  <v-icon size="20" v-if="item.iconColor" :color="item.iconColor">{{ item.action }}</v-icon>
                  <v-icon size="20" v-else>{{ item.action }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-light">
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-row class="pl-0" v-for="(child, index) in item.items" :key="index" no-gutters>
              <v-list-item v-if="item.click" @click="handleMenuClick({ item, index })">
                <v-list-item-icon class="mr-2">
                  <v-icon color="grey darken-1" size="18">
                    {{ child.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-card flat color="transparent">
                    <div class="font-weight-regular text-subtitle-2 text--secondary">
                      <div>{{ child.title }}</div>
                    </div>
                    <div class="text-body-2 font-weight-light">
                      {{ child.detail }}
                    </div>
                    <div class="font-weight-regular text-subtitle-2 text--disabled" v-if="child.thirdLine">
                      {{ child.thirdLine }}
                    </div>
                  </v-card>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-else>
                <v-list-item-icon class="mr-2">
                  <v-icon color="grey darken-1" size="18">
                    {{ child.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-card flat color="transparent">
                    <div class="font-weight-regular text-subtitle-2 text--secondary">
                      <div>{{ child.title }}</div>
                    </div>
                    <div class="text-body-2 font-weight-light">
                      {{ child.detail }}
                    </div>
                    <div class="font-weight-regular text-subtitle-2 text--disabled" v-if="child.thirdLine">
                      {{ child.thirdLine }}
                    </div>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-row>
            <v-btn
              v-if="item.title.includes('Conditions') && item.items.length > 0 && showAddEditButtons"
              color="primary"
              class="ml-8 mt-2"
              text
              @click="$refs.conditionsDialog.show = true"
            >
              <v-icon left>mdi-playlist-edit</v-icon>Add/Remove
            </v-btn>

            <v-btn
              v-if="item.title.includes('Medications') && item.items.length > 0 && showAddEditButtons"
              color="primary"
              class="ml-8 mt-2"
              text
              @click="$refs.medicationsDialog.show = true"
            >
              <v-icon left>mdi-playlist-edit</v-icon>Add/Remove
            </v-btn>

            <!-- <v-row v-if="item.component" no-gutters>
              <patient-activity-calendar :patient="patient" />
            </v-row> -->
          </v-list-group>
        </v-list>
      </div>
    </v-slide-y-transition>
    <patient-conditions-dialog ref="conditionsDialog" :showActivator="false" :listItem="true" :patient="patient" />
    <patient-medications-dialog ref="medicationsDialog" :showActivator="false" :listItem="true" :patient="patient" />
  </v-card>
</template>

<script>
import bus from '@/core/helpers/bus'
import { mapState, mapActions } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import moment from 'moment-timezone'
import PatientConditionsDialog from './dialogs/PatientConditionsDialog.vue'
import PatientMedicationsDialog from './dialogs/PatientMedicationsDialog.vue'
import PatientActionBar from './PatientActionBar.vue'
import get from 'lodash/get'

export default {
  components: {
    PatientConditionsDialog,
    PatientMedicationsDialog,
    PatientActionBar,
  },
  mixins: [HelperMixin],
  name: 'MobilePatientHeader',
  data() {
    return {
      patientIntegrations: [],
      patientCareTeam: [],
      chartAccessList: [],
      options: {},
      rawCollaborators: null,
      inviteList: [],
      updatingInvited: false,
      lastChartReviewOnPatientLoad: null,
    }
  },
  props: {
    allowEdits: {
      type: Boolean,
      default: true,
    },
    patient: {
      type: Object,
      default: null,
    },
    allowEditing: {
      type: Boolean,
      default: true,
    },
  },
  watch: {},
  computed: {
    ...mapState('auth', ['user', 'rpmPartner', 'appTheme', 'partner', 'configuration']),
    showAddEditButtons() {
      if (this.allowEditing) {
        return true
      }
      return false
    },
    enrollmentStartDate() {
      if (get(this, 'patient.rpm.enrollment.enrolled', false)) {
        let enrollmentStartDate = new Date(this.patient.rpm.enrollment.start).getTime()
        return this.getDateFromTimestamp(enrollmentStartDate)
      } else {
        return 'Not currently enrolled'
      }
    },
    patientSubtitleLine() {
      // TODO: Refactor
      let shortGender = this.patient.gender.charAt(0)
      let displayBirthdate = null
      let age = null
      if (this.patient.birthdate) {
        // change birthdate into display birthdate
        displayBirthdate =
          this.patient.birthdate.substring(5, 7) +
          '/' +
          this.patient.birthdate.substring(8, 10) +
          '/' +
          this.patient.birthdate.substring(0, 4)

        let constructedDateTimestamp = new Date(
          this.patient.birthdate.substring(0, 4),
          this.patient.birthdate.substring(5, 7) - 1,
          this.patient.birthdate.substring(8, 10),
          0,
          0,
          0,
          0
        ).getTime()

        let ageDifMs = Date.now() - constructedDateTimestamp
        let ageDate = new Date(ageDifMs) // miliseconds from epoch
        age = Math.abs(ageDate.getUTCFullYear() - 1970)
      }
      return (
        age +
        ' ' +
        shortGender +
        ' · ' +
        displayBirthdate +
        ' · ' +
        get(this.patient, 'ownerPatientID', 'No Patient ID')
      )
    },

    //font-weight-medium text-subtitle-2

    isSmall() {
      return this.$vuetify.breakpoint.sm || false
    },
    isMed() {
      return this.$vuetify.breakpoint.md || false
    },
    isLg() {
      return this.$vuetify.breakpoint.lg || false
    },
    summaryItems() {
      // each item will have a title and a detail
      let protoItems = []

      if (this.chartAlerts.length > 0) {
        let chartAlerts = {
          action: 'mdi-alert-circle',
          iconColor: 'primary',
          title: 'Alerts (' + this.chartAlerts.length + ')',
          items: [],
          icon: 'mdi-chevron-down',
          click: true,
        }

        this.chartAlerts.forEach(alert => {
          chartAlerts.items.push({
            title: null,
            detail: alert.note,
            action: 'createNote',
            actionPayload: alert,
            icon: 'mdi-alert-circle-outline',
            event: alert.event,
          })
        })
        protoItems.push(chartAlerts)
      }

      let demographics = {
        action: 'mdi-account-card-details-outline',
        title: 'Demographics',
        items: [],
        icon: 'mdi-chevron-down',
      }

      if (this.patient.displayBirthdate) {
        demographics.items.push({
          icon: 'mdi-calendar',
          title: 'Date of Birth',
          detail: this.patient.displayBirthdate,
        })
      }
      if (this.patient.gender) {
        demographics.items.push({
          icon: 'mdi-account',
          title: 'Gender',
          detail: this.patient.gender,
        })
      }

      if (this.patient.displayAddress) {
        demographics.items.push({
          icon: 'mdi-map-marker',
          title: 'Address',
          detail: this.patient.displayAddress,
        })
      }

      if (this.patient.primaryPhone) {
        demographics.items.push({
          icon: 'mdi-phone',
          title: 'Primary Phone',
          detail: this.patient.primaryPhone,
        })
      }

      if (this.patient.smsPhone) {
        demographics.items.push({
          icon: 'mdi-cellphone-text',
          title: 'Phone for Messaging',
          detail: this.patient.smsPhone,
        })
      }

      demographics.items.push({
        icon: 'mdi-calendar-check',
        title: 'Date of Enrollment',
        detail: this.enrollmentStartDate,
      })

      protoItems.push(demographics)

      // let activity = {
      //   action: 'mdi-calendar-multiselect',
      //   title: 'Patient Activity',
      //   items: [],
      //   icon: 'mdi-chevron-down',
      //   component: true,
      // }

      // protoItems.push(activity)

      if (this.conditionsArray.length > 0) {
        let conditions = {
          action: 'mdi-bandage',
          title: 'Conditions (' + this.conditionsArray.length + ')',
          items: [],
          click: true,
        }

        this.conditionsArray.forEach(condition => {
          conditions.items.push({
            title: condition.icd10,
            detail: condition.description,
            action: 'createNote',
            actionPayload: condition,
          })
        })
        protoItems.push(conditions)
      } else if (this.showAddEditButtons) {
        // the entire activator should be the conditions add dialog.
        let conditions = {
          action: 'mdi-bandage',
          title: 'Conditions (0)',
          items: [],
          click: true,
          activator: true,
          icon: 'mdi-plus',
        }
        protoItems.push(conditions)
      }

      if (this.medicationsArray.length > 0) {
        let medications = {
          action: 'mdi-pill',
          title: 'Medications (' + this.medicationsArray.length + ')',
          items: [],
          click: true,
          icon: 'mdi-chevron-down',
        }

        this.medicationsArray.forEach(medication => {
          medications.items.push({
            title: medication.name,
            detail: medication.strength,
            action: 'createNote',
            actionPayload: medication,
          })
        })
        protoItems.push(medications)
      } else if (this.showAddEditButtons) {
        // the entire activator should be the conditions add dialog.
        let medications = {
          action: 'mdi-pill',
          title: 'Medications (0)',
          items: [],
          click: true,
          activator: true,
          icon: 'mdi-plus',
        }
        protoItems.push(medications)
      }

      if (this.chartAccessList.length > 0) {
        let careTeam = {
          action: 'mdi-account-multiple',
          title: 'Chart Access (' + this.chartAccessList.length + ')',
          items: [],
          icon: 'mdi-chevron-down',
        }

        this.chartAccessList.forEach(item => {
          let itemObject = { title: item.displayName }

          if (item.email) {
            itemObject.detail = item.email
            itemObject.icon = 'mdi-account-circle'
          } else if (item.type === 'Partners') {
            itemObject.detail = 'Organization'
            itemObject.icon = 'mdi-domain'
          }
          careTeam.items.push(itemObject)
        })
        protoItems.push(careTeam)
      }

      return protoItems
    },
    conditionsArray() {
      let conditions = []
      if (this.patient && this.patient.conditions) {
        this.patient.conditions.forEach(problem => {
          let objectProto = problem.split('|')
          let problemObject = {}
          problemObject.name = objectProto[0]
          problemObject.description = objectProto[1]
          problemObject.icd10 = objectProto[0]
          problemObject.fullItem = problem
          conditions.push(problemObject)
        })
      }
      return conditions
    },
    medicationsArray() {
      let medications = []
      if (this.patient && this.patient.medications) {
        this.patient.medications.forEach(medication => {
          let objectProto = medication.split('|')
          let medicationObject = {}
          medicationObject.name = objectProto[0]
          medicationObject.displayName = objectProto[0]
          if (objectProto[1]) {
            medicationObject.strength = objectProto[1]
            medicationObject.displayName = medicationObject.displayName + ' (' + objectProto[1] + ')'
          }
          medicationObject.fullItem = medication
          medications.push(medicationObject)
        })
      }
      return medications
    },
    chartAlerts() {
      let alerts = []
      if (this.patient.thresholdBreachAlertNotifications) {
        this.patient.thresholdBreachAlertNotifications.forEach(alert => {
          alert.icon = 'mdi-alert-circle'
          alerts.push(alert)
        })
      }

      if (this.patient.inactivityAlertNotifications) {
        this.patient.inactivityAlertNotifications.forEach(alert => {
          alert.icon = 'mdi-calendar-clock'
          alerts.push(alert)
        })
      }
      return alerts
    },
    patientInfoSummary() {
      let snippet = ''
      if (this.lastChartReviewOnPatientLoad) {
        snippet =
          this.patient.firstName +
          "'s chart was last reviewed on " +
          this.getConversationalDateAndTimeFromTimestamp(this.lastChartReviewOnPatientLoad) +
          '. '
      }

      if (this.patient.rpmMostRecentData) {
        let article = this.patient.firstName + "'s"
        if (snippet != '') {
          article = 'Her'

          if (this.patient.gender === 'MALE') {
            article = 'His'
          }
        }
        snippet =
          snippet +
          article +
          ' most recent measurement was taken on ' +
          this.getConversationalDateAndTimeFromTimestamp(this.patient.rpmMostRecentData) +
          '. '
      }
      return snippet
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    shouldAlertAMissedReading() {
      // only alert if it was more than 3 days ago?
      if (this.patient && this.patient.daysSinceLast && this.patient.daysSinceLast > 3) {
        return (
          this.patient.firstName +
          "'s last measurement was more than " +
          this.relativeTime(this.patient.rpmMostRecentData) +
          '.'
        )
      }
      return null
    },
  },
  methods: {
    ...mapActions('patient', ['getIntegrations']),
    clickedListGroup(category) {
      // go handle the item correctly
      if (category.items.length === 0) {
        // so you clicked an empty list.
        // determine if I care.
        if (category.title.includes('Conditions')) {
          //open the conditions dialog
          this.$refs.conditionsDialog.show = true
        }
        if (category.title.includes('Medications')) {
          //open the medications dialog
          this.$refs.medicationsDialog.show = true
        }
      }
    },
    handleMenuClick({ item, index }) {
      console.log(item)
      if (item.title.includes('Conditions')) {
        this.addConditionNote(item.items[index].actionPayload)
      }

      if (item.title.includes('Medications')) {
        this.addMedicationNote(item.items[index].actionPayload)
      }

      if (item.title.includes('Alerts')) {
        this.addEventNote(item.items[index].event)
      }
    },
    addConditionNote(item) {
      bus.$emit('addNoteToPoint', { type: 'condition', data: item })
    },
    addMedicationNote(item) {
      bus.$emit('addNoteToPoint', { type: 'medication', data: item })
    },
    addEventNote(event) {
      if (event) {
        bus.$emit('addNoteToPoint', {
          type: 'metric',
          data: event,
        })
      } else {
        bus.$emit('addNoteToPoint')
      }
    },
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return ''
    },
  },
  mounted() {},
  beforeDestroy() {},
}
</script>

<style></style>
