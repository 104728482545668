<template>
  <v-snackbar class="notification" timeout="3000" left bottom v-model="showSnackbar">
    <v-row no-gutters style="width: 300px">
      <v-col cols="2">
        <v-row no-gutters style="height: 100%">
          <v-icon class="mr-3" large>mdi-message-video</v-icon>
          <v-divider vertical />
        </v-row>
        <v-spacer />
      </v-col>

      <v-col cols="10">
        <div class="ml-4">Please close the active video to leave the current patient.</div>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import bus from '@/core/helpers/bus'

export default {
  data: () => {
    return {
      patient: null,
      showSnackbar: false,
    }
  },
  methods: {
    handleShow(patient = {}) {
      this.patient = patient
      this.showSnackbar = true
    },
  },
  created() {
    bus.$on('videosnack:show', this.handleShow)
  },
  destroyed() {
    bus.$off('videosnack:show', this.handleShow)
  },
}
</script>