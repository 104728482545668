<template>
  <v-card
    style="width:100%; min-width: 450px;"
    flat
    class="pb-4"
    outlined
    :loading="savingNote"
  >
    <v-row justify="space-between" class="px-3 pt-2" no-gutters>
      <v-list-item class="px-1">
        <v-list-item-avatar size="35" color="primary">
          <v-icon size="20" dark>mdi-account</v-icon>
        </v-list-item-avatar>
        <v-list-item-content class="mr-0">
          <v-list-item-title class="font-weight-medium text-subtitle-1">
            {{ user.firstName }} {{ user.lastName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Right Now
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-row align="center" no-gutters>
            <v-btn small @click="openTagLine" depressed rounded>
              <v-icon small class="mr-0" left color="primary">mdi-at</v-icon>
              <span v-if="selectedMentions.length === 0">
                Tag People
              </span>
              <span v-else>
                <span>{{ selectedMentions[0].displayName }}</span>
                <span style="opacity:0.5" v-if="selectedMentions.length > 1">
                  +{{ selectedMentions.length - 1 }}
                </span>
              </span>
            </v-btn>
            <v-chip
              v-if="selectedMentions.length > 0"
              small
              class="px-1"
              color="transparent"
            >
            </v-chip>
          </v-row>
        </v-list-item-action>
      </v-list-item>
    </v-row>
    <chart-note-media v-if="chartNoteMedia !== null" :item="chartNoteMedia" />

    <v-row no-gutters>
      <v-autocomplete
        :menu-props="{ closeOnContentClick: true }"
        background-color="grey lighten-4"
        class="mt-3 pt-0 px-4 mb-3 mx-0"
        ref="tagUsers"
        full-width
        single-line
        :search-input.sync="searchInput"
        @change="searchInput = null"
        v-model="selectedMentions"
        append-icon=""
        item-text="searchName"
        return-object
        :items="orgUsers"
        multiple
        hide-details
        solo
        flat
        placeholder="Tag People"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip
            class="mx-0 mr-2"
            close
            @click:close="removeMention(item)"
            v-if="index < 2"
          >
            <span>{{ item.displayName }}</span>
          </v-chip>
          <span v-if="index === 2" class="grey--text caption ml-1">
            (+{{ selectedMentions.length - 2 }})
          </span>
        </template>

        <template v-slot:item="data">
          <v-list-item v-bind="data.attrs" v-on="data.on">
            <v-list-item-avatar color="grey lighten-3">
              <v-icon>mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.displayName"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.email"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-row>

    <v-row v-show="savedNoteTemplates.length" no-gutters>
      <v-select
        class="pt-0 px-4 mb-3 mx-0"
        hide-details
        background-color="grey lighten-4"
        full-width
        single-line
        solo
        flat
        placeholder="Note Template"
        :items="savedNoteTemplates"
        item-text="title"
        v-model="selectedTemplate"
        clearable
        return-object
      >
      </v-select>
    </v-row>

    <v-textarea
      class="mt-0 pt-0 px-4 mb-3 mx-0 font-weight-regular text-body-1 "
      ref="noteInput"
      solo
      flat
      id="noteInput"
      name="input-7-4"
      placeholder="Note:"
      auto-grow
      v-model="sessionNotes"
      hide-details
      @keydown.enter.stop.prevent="saveSessionNotes()"
      background-color="grey lighten-4"
    >
    </v-textarea>

    <v-row class="px-4 pt-4" justify="space-between" no-gutters>
      <v-btn depressed @click="cancelNote()">Cancel</v-btn>
      <v-btn color="primary" depressed @click="saveSessionNotes()">Save</v-btn>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import HelperMixin from '@/core/mixins/HelperMixin'
import ChartNoteMedia from './ChartNoteMedia.vue'
import get from 'lodash/get'

export default {
  components: { ChartNoteMedia },
  data() {
    return {
      selectedTemplate: null,
      rolledUp: false,
      searchInput: null,
      selectedMentions: [],
      showUserTags: false,
      currentlyTrackedPatient: null,
      isTracking: false,
      rpmInterval: null,
      clockInterval: null,
      startTimeForSession: null,
      elapsedTime: null,
      fav: true,
      menu: false,
      sessionNotes: null,
      menuKey: 0,
      closeOnClick: false,
      savingNote: false,
    }
  },
  mixins: [HelperMixin],
  props: {
    setRolledUp: {
      type: Boolean,
      default: false,
    },
    refPoint: {
      type: Object,
      default: null,
    },
    taggedUser: {
      type: Object,
      default: null,
    },
    noteType: {
      type: Object,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('auth', [
      'user',
      'configuration',
      'partner',
      'appTheme',
      'orgUsers',
      'demoFeatures',
    ]),
    ...mapState('patient', ['patient']),
    ...mapState('notes', ['savedNoteTemplates']),
    dateOnOpen() {
      return new Date().getTime()
    },
    clearingAnAlert() {
      if (
        get(this, 'refPoint.data.alert', false) &&
        get(this, 'patient.rpmUnseenAlertsArray.length', 0)
      ) {
        console.log('clering an alert')
        return true
      }
      console.log('not clearing an alert')
      return false
    },
    refValue() {
      if (this.refPoint) {
        if (this.refPoint.type === 'metric') {
          return this.refPoint.value
        }
      }
      return 'val'
    },
    normalizedReferenceData() {
      if (this.refPoint) {
        if (
          this.refPoint.type === 'metric' &&
          this.refPoint.data &&
          this.refPoint.data
        ) {
          // if this is
          return (
            this.refPoint.data.dashboardAbbreviation +
            ' ' +
            this.refPoint.data.eventString +
            ' | ' +
            this.refPoint.data.readableDate
          )
        }
        if (
          this.refPoint.type === 'medication' &&
          this.refPoint.data &&
          this.refPoint.data.displayName
        ) {
          return this.refPoint.data.displayName
        }
        if (
          this.refPoint.type === 'condition' &&
          this.refPoint.data &&
          this.refPoint.data.name
        ) {
          return this.refPoint.data.name
        }
        return 'refPoint title'
      }
      return null
    },
    refPointColor() {
      if (this.refPoint && this.refPoint.data && this.refPoint.data.alert) {
        return 'yellow'
      }
      return null
    },
    partnerDisplayName() {
      if (this.partner && this.partner.name) {
        return this.partner.name
      } else if (this.user && this.user.partnerID) {
        return this.user.partnerID
      }
      return null
    },
    chartNoteMedia() {
      if (this.refPoint && this.refPoint.data) {
        let noteObject = {
          id: null,
          author: null,
          timestamp: 0,
          fullNote: { eventData: { referenceData: this.refPoint } },
          unprocessedNote: { eventData: { referenceData: this.refPoint } },
          mentions: [],
          media: this.refPoint.type,
          mediaType: this.refPoint.type,
          text: null,
          replies: [],
        }
        return noteObject
      }

      return null
    },
  },
  watch: {
    selectedTemplate(val) {
      if (val) {
        bus.$emit('toast', { type: 'success', text: 'Template applied' })
        this.applyTextExpansionToString({
          patient: this.patient,
          message: val.content,
        }).then(result => {
          this.sessionNotes = result
        })
      }
    },
    open(val) {
      this.sessionNotes = null
      if (val) {
        this.$nextTick(() => this.focusOnNote())
        if (this.noteType) {
          this.$nextTick(() => (this.selectedTemplate = this.noteType))
        }
      } else {
        this.selectedMentions = []
        this.selectedTemplate = null
        //this.refPoint = null
      }
    },
    noteType(val) {
      if (val) {
        this.selectedTemplate = val
      }
    },
    taggedUser(val) {
      if (val) {
        this.$nextTick(() => this.selectedMentions.push(val))
      }
    },
  },
  methods: {
    ...mapActions('notes', ['addChartNote', 'getSavedNoteTemplates']),
    ...mapActions('masstext', ['applyTextExpansionToString']),
    ...mapActions('taskboard', ['prepareEventData']),
    ...mapActions('taskboard', ['removeUnseenAlertFromPatient']),
    templateAppliedColor(note) {
      if (this.selectedTemplate && note.id === this.selectedTemplate.id) {
        return 'primary'
      } else {
        return 'grey lighten-4'
      }
    },
    removeTemplateApplication() {
      this.selectedTemplate = null
    },
    insertUser(user) {
      this.selectedMentions.push(user)
    },
    openTagLine() {
      this.$refs.tagUsers.focus()
      this.$refs.tagUsers.isMenuActive = true
    },
    removeMention(mention) {
      let index = this.selectedMentions.indexOf(mention)
      if (index > -1) {
        this.selectedMentions.splice(index, 1)
      }
    },
    saveSessionNotes() {
      if (this.sessionNotes) {
        this.savingNote = true
        let noteMentions = []
        if (this.selectedMentions.length > 0) {
          this.selectedMentions.forEach(mention => {
            let userTag = { name: mention.displayName, userID: mention._id }
            noteMentions.push(userTag)
          })
        }
        let templateID = null
        if (this.selectedTemplate) {
          templateID = this.selectedTemplate.id
        }

        this.addChartNote({
          patient: this.$route.params.id,
          partner: this.partner._id,
          sessionNote: this.sessionNotes,
          referenceData: this.refPoint,
          noteMentions: noteMentions,
          templateID: templateID,
        }).then(() => {
          // just store this timestamp in firebase under partnerID/patient/notemade: timestamp

          // if this has an alert, clear it from the patient object and refresh the various things.
          if (this.clearingAnAlert) {
            this.removeUnseenAlertFromPatient({
              patientID: this.patient._id,
              event: this.refPoint.data.eventType,
            })
              .then(() => {
                if (
                  this.patient.rpmUnseenAlertsArray &&
                  this.patient.rpmUnseenAlertsArray.length > 0 &&
                  this.patient.rpmUnseenAlertsArray.includes(
                    this.refPoint.data.eventType
                  )
                ) {
                  let index = this.patient.rpmUnseenAlertsArray.indexOf(
                    this.refPoint.data.eventType
                  )
                  if (index > -1) {
                    this.patient.rpmUnseenAlertsArray.splice(index, 1)
                  }
                }
                bus.$emit('toast', { type: 'success', text: 'Alert Resolved' })
                bus.$emit('refreshNoteList')
                this.menu = false
                this.closeOnClick = true
                this.sessionNotes = null
                this.savingNote = false
                this.$emit('noteSaved')
              })
              .catch(error => {
                console.log(error)
                bus.$emit('refreshNoteList')
                bus.$emit('toast', {
                  type: 'error',
                  text: 'Note saved, but alert unresolved. Try again later.',
                })
                this.menu = false
                this.closeOnClick = true
                this.sessionNotes = null
                this.savingNote = false
                this.$emit('noteSaved')
              })
          } else {
            bus.$emit('refreshNoteList')
            bus.$emit('toast', { type: 'success', text: 'Note Saved' })
            this.menu = false
            this.closeOnClick = true
            this.sessionNotes = null
            this.savingNote = false
            this.$emit('noteSaved')
          }
        })
      } else {
        this.menu = false
        this.closeOnClick = true
      }
    },
    focusOnNote() {
      this.$refs.noteInput.focus()
    },
    cancelNote() {
      this.$emit('noteCanceled')

      this.closeOnClick = true
      this.sessionNotes = null
      this.savingNote = false
      this.selectedTemplate = null
      this.selectedMentions = []
    },
  },
  mounted() {
    if (this.taggedUser) {
      this.selectedMentions.push(this.taggedUser)
    }

    if (this.noteType) {
      console.log('mounted with a type')
      this.$nextTick(() => (this.selectedTemplate = this.noteType))

      console.log(this.noteType)
    }

    this.rolledUp = this.setRolledUp
  },
}
//End
</script>

<style></style>
