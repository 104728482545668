<template>
  <v-row no-gutters class="px-2">
    <v-card class="flex pa-2 py-1" outlined>
      <v-row no-gutters align="center" justify="space-between">
        <patient-action-bar-export-facesheet-menu />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              @click="handleMessengerClick"
              v-bind="attrs"
              v-on="on"
              icon
            >
              <!-- <v-icon>mdi-message-text-outline</v-icon> -->
              <v-badge
                :value="unreadMessagesCount"
                :content="unreadMessagesCount"
                color="primary"
                overlap
              >
                <v-icon>mdi-message-text-outline</v-icon>
              </v-badge>
            </v-btn>
          </template>
          Send a Message
        </v-tooltip>

        <patient-action-bar-add-chart-note-menu :enablePopups="enablePopups" />

        <telehealth-menu v-if="false" :enablePopups="enablePopups" />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="handleVideoClick" v-bind="attrs" v-on="on" icon>
              <!-- <v-icon>mdi-message-text-outline</v-icon> -->

              <v-icon>mdi-message-video</v-icon>
            </v-btn>
          </template>
          Open Telehealth Room
        </v-tooltip>

        <patient-action-bar-share-chart-menu :patient="patient" />
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TelehealthMenu from './TelehealthMenu.vue'
import PatientActionBarAddChartNoteMenu from './PatientActionBarAddChartNoteMenu.vue'
import bus from '../core/helpers/bus'
import PatientActionBarExportFacesheetMenu from './PatientActionBarExportFacesheetMenu.vue'
import PatientActionBarShareChartMenu from './PatientActionBarShareChartMenu.vue'

export default {
  components: {
    TelehealthMenu,
    PatientActionBarAddChartNoteMenu,
    PatientActionBarExportFacesheetMenu,
    PatientActionBarShareChartMenu,
  },
  data() {
    return {
      menu: false,
      refPoint: null,
      showAlert: false,
      alertInterval: null,
      alertShown: 0,
      alertForDisplay: null,
    }
  },
  props: {
    elementColor: null,
    collapsable: {
      type: Boolean,
      default: true,
    },
    includeTimer: {
      type: Boolean,
      default: true,
    },
    enablePopups: {
      type: Boolean,
      default: true,
    },
    justify: {
      type: String,
      default: 'end',
    },
    patient: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState('auth', [
      'user',
      'appTheme',
      'partner',
      'temporaryAuthToken',
      'tempAuthExpiration',
    ]),
    ...mapState('conversation', ['unreadMessagesCount']),
    collapsed() {
      if (!this.collapsable) {
        return false
      }
      // if its medium then collapse it
      if (this.$vuetify.breakpoint.mdAndDown) {
        return true
      }
      return false
    },
    patientNavigationList() {
      let chartSections = []

      // let patientInfo = {icon: 'mdi-account-card-details', title: 'Patient Information', tooltip: 'Patient Information', destinationName: 'PatientInfo'}
      // chartSections.push(patientInfo)

      // let patientChart = {icon: 'mdi-chart-areaspline', title: 'Patient Monitoring', tooltip: 'Patient Monitoring', destinationName: 'Patient'}
      // chartSections.push(patientChart)

      // let chartShares = {icon: 'mdi-account-multiple', title: 'Care Team', tooltip: 'Care Team', destinationName: 'Followers'}
      // chartSections.push(chartShares)

      // let exportFacesheet = {icon: 'mdi-cloud-print-outline', title: 'Export Facesheet', tooltip: 'Export Facesheet', actionName: 'exportFacesheet'}
      // chartSections.push(exportFacesheet)

      // let patientChartNotes = {icon: 'mdi-file-document-box-plus-outline', title: 'Chart Notes', tooltip: 'Add a Note', actionName: 'note'}
      // chartSections.push(patientChartNotes)

      // let patientMessages = {
      //   icon: 'mdi-message-text-outline',
      //   title: 'Messages',
      //   tooltip: 'Send a Message',
      //   destinationName: 'Messages',
      // }
      // chartSections.push(patientMessages)

      // let telehealthRoom = {icon: 'mdi-message-video', title: 'Create Telehealth Room', tooltip: 'Start a Telehealth Visit', actionName: 'telehealth'}
      // chartSections.push(telehealthRoom)

      return chartSections
    },
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    ...mapActions('patient', ['prepareForSigningOut']),
    ...mapActions('device', ['shouldShowDeviceList']),
    ...mapActions('dashboard', ['getPatientMessages', 'prepareForSignout']),
    ...mapActions('patientdrawer', ['exportFacesheet']),
    handleMessengerClick() {
      if (this.patient.smsPhone) {
        bus.$emit('messenger:open', { patient: this.patient })
      } else {
        bus.$emit('smssnack:show', this.patient)
      }
    },
    handleVideoClick() {
      bus.$emit('video:open', { patientID: this.patient._id })
    },
    handleFacesheetClick() {
      this.exportFacesheet(this.patient)
    },
    navigateWithinPatientChart(destination) {
      if (destination.destinationName) {
        this.$router.push({
          name: destination.destinationName,
          params: { id: this.patient._id },
        })
      }

      if (destination.actionName === 'exportFacesheet') {
        this.exportFacesheet(this.patient)
      }
    },
  },
  mounted() {
    // when this is mounted, then you should display the to do items like a toast but the lower left. and do it cool
    //alert(this.patient.thresholdBreachAlertNotifications.length)
  },
}
</script>

<style></style>
