<template>
  <v-container v-if="ready" fluid fill-height :class="patientClass" style="align-items: start">
    <portal to="subdrawer">
      <patient-drawer-content :patient="patient" />
    </portal>

    <portal to="appBarContentLeft">
      <patient-chart-tabs />
    </portal>

    <portal to="appBarContentRight">
      <v-row justify="end" align="center" no-gutters>
        <portal-target name="videoTimer" slim />
        <patient-chart-timer />
      </v-row>
    </portal>

    <video-popup ref="videoPopup" @link:click="handleLinkClick" />

    <router-view></router-view>

    <patient-messenger-popup dark collapsable closeable />

    <snackbar-sms-required />
    <snackbar-video-active />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PatientDrawerContent from '../components/PatientDrawerContent.vue'
import PatientChartTabs from '../components/PatientChartTabs.vue'
import PatientChartTimer from '../components/PatientChartTimer.vue'
import SnackbarSmsRequired from '../components/snackbars/SnackbarSmsRequired.vue'
import SnackbarVideoActive from '../components/snackbars/SnackbarVideoActive.vue'
import PatientMessengerPopup from '../components/popups/PatientMessengerPopup.vue'
import VideoPopup from '../components/videoroom/VideoPopup.vue'
import bus from '@/core/helpers/bus'

export default {
  components: {
    PatientDrawerContent,
    PatientChartTabs,
    PatientChartTimer,
    PatientMessengerPopup,
    SnackbarSmsRequired,
    SnackbarVideoActive,
    VideoPopup,
  },
  data: () => {
    return {
      ready: false,
      videoActive: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('patient', ['patient']),
    // ...mapState('patientv2', { patient2: (state) => state.patient }),
    patientClass() {
      return { 'pa-0': this.$route.name === 'Messages' }
    },
  },
  watch: {
    user(user) {
      if (user) {
        this.fetchPatient()
      }
    },
  },
  methods: {
    ...mapActions('patient', ['getPatient']),
    // ...mapActions('patientv2', { getPatient2: 'getPatient' }),
    async fetchPatient() {
      let patientId = this.$route.params.id
      await this.getPatient({ id: patientId })
      // await this.getPatient2({ id: patientId })
      // console.log('patientId:', this.patient._id)
      this.ready = true
    },
    handleLinkClick(content) {
      bus.$emit('messenger:open', { patient: this.patient, content })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.videoPopup.activeInviteeToken) {
      bus.$emit('videosnack:show')
      return next(false)
    }
    next()
  },
  async created() {
    if (this.user) {
      this.fetchPatient()
    }
  },
}
</script>
