<template>
  <v-dialog v-model="menu" width="500">
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            class="mr-1"
            icon
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            <v-icon class="mt-n1">mdi-file-document-box-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>Add Chart Note</span>
      </v-tooltip>
    </template>

    <add-chart-note
      :open="menu"
      ref="addNoteCard"
      :refPoint="refPoint"
      :taggedUser="taggedUser"
      :noteType="noteType"
      @noteSaved=";(menu = false), (refPoint = null), (taggedUser = null)"
      @noteCanceled=";(menu = false), (refPoint = null), (taggedUser = null)"
    />
  </v-dialog>
</template>

<script>
import bus from '@/core/helpers/bus'
import HelperMixin from '@/core/mixins/HelperMixin'
import AddChartNote from '@/components/AddChartNote'

export default {
  mixins: [HelperMixin],
  name: 'addChartNoteMenu',
  components: {
    AddChartNote,
  },
  props: {
    enablePopups: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menu: false,
      refPoint: null,
      taggedUser: null,
      noteType: null,
    }
  },
  watch: {
    menu(val) {
      if (!val) {
        this.refPoint = null
        this.taggedUser = null
      }
    },
  },
  mounted() {
    if (this.enablePopups) {
      bus.$on('addNoteToPoint', payload => {
        this.refPoint = null
        this.taggedUser = null
        this.noteType = null
        this.refPoint = payload
        if (!this.menu) {
          // open the note with the information in the payload
          this.menu = true
        }
      })

      bus.$on('addNoteWithTag', payload => {
        this.taggedUser = null
        this.refPoint = null
        this.noteType = null
        this.taggedUser = payload
        if (!this.menu) {
          // open the note with the information in the payload

          this.menu = true
        }
      })

      bus.$on('addNoteWithType', payload => {
        this.refPoint = null
        this.taggedUser = null
        this.noteType = null
        this.noteType = payload
        if (!this.menu) {
          // open the note with the information in the payload

          this.menu = true
        }
      })

      bus.$on('addNote', () => {
        this.refPoint = null
        this.taggedUser = null
        this.noteType = null
        if (!this.menu) {
          // open the note with the information in the payload

          this.menu = true
        }
      })
    }
  },
}
</script>

<style></style>
