<template>
  <v-card class="pa-3" style="overflow:hidden" rounded="lg" color="grey lighten-4" flat outlined >

<v-row  align="center" no-gutters>
        

  <v-col >
    <v-row no-gutters class="font-weight-light text-h5 flex-nowrap">
        <span>{{ patient.firstName }} {{ patient.lastName }}</span>
      </v-row>
      <v-row class="font-weight-regular text-subtitle-2 text--secondary" no-gutters>
        <span>{{patientSubtitleLine}}</span>
      </v-row>
  </v-col>
  <v-icon v-if="false" color="grey lighten-2" size="65" >mdi-folder-account-outline</v-icon>
</v-row>  

  <v-card v-if="false" class="mb-4" flat min-height="240" color="transparent">
<!-- <v-row no-gutters justify="space-between" class="px-4 pb-2">
  <span class="text-caption text--secondary">MOST RECENT</span>
  <span class="text-caption text--secondary">{{relativeTime(item.rpmMostRecentData)}}</span>
</v-row> -->
<!-- <v-row v-if="item.bp !== '-'"  no-gutters class="px-4 pb-2">
    <v-col cols="12">
  <v-card flat class="pa-1 px-2 caption" color="grey lighten-4" rounded>
      <v-row no-gutters justify="space-between">
      <span>Blood Pressure</span> <v-spacer/>{{item.bp}}
      </v-row>
  </v-card>
    </v-col>
</v-row>
<v-row v-if="item.hr !== '-'"  no-gutters class="px-4 pb-2">
    <v-col cols="12">
  <v-card flat class="pa-1 px-2 text-caption" color="grey lighten-4" rounded>
      <v-row no-gutters justify="space-between">
      <span>Heart Rate</span> <v-spacer/>{{item.hr}}
      </v-row>
  </v-card>
    </v-col>
</v-row>

<v-row v-if="item.wt !== '-'"  no-gutters class="px-4 pb-2">
    <v-col cols="12">
  <v-card flat class="pa-1 px-2 text-caption" color="grey lighten-4" rounded="lg">
      <v-row no-gutters justify="space-between">
      <span>Weight</span> <v-spacer/>{{item.wt}}
      </v-row>
  </v-card>
    </v-col>
</v-row>

<v-row v-if="item.glu !== '-'"  no-gutters class="px-4 pb-2">
    <v-col cols="12">
  <v-card flat class="pa-1 px-2 text-caption" color="grey lighten-4" rounded="lg">
      <v-row no-gutters justify="space-between">
      <span>Glucose</span> <v-spacer/>{{item.glu}}
      </v-row>
  </v-card>
    </v-col>
</v-row> -->

<v-row no-gutters justify="space-between" class="px-4 pb-2 mt-3">
  <span class="text-caption text--secondary">PROGRESS</span>
  <span class="text-caption text--secondary">{{16 - item.daysNeeded}}/16 Days</span>
</v-row>

  <v-row no-gutters class="px-4 pb-2">
  <v-progress-linear height="4" rounded :value="item.progress" />
  </v-row>
<v-row class="text-body-2 text--secondary px-4" no-gutters>

      {{item.actionNeeded}} The most recent reading was {{relativeTime(item.rpmMostRecentData)}}

</v-row>

  <v-row v-if="item.lastNote" no-gutters justify="space-between" class="px-4 pb-2 mt-3">
  <span class="text-caption text--secondary">LAST NOTE</span>
  <span class="text-caption text--secondary">{{relativeTime(item.lastNote.timestamp)}}</span>
</v-row>


<v-row v-if="item.lastNote" no-gutters class="px-4">
  <v-card max-height="100" class="text-body-2 text--secondary" flat >
      <span class="font-weight-medium">{{item.lastNote.author}}:</span> <span >{{item.lastNote.note}}</span>
  </v-card>
</v-row>


  </v-card>


        </v-card>
</template>

<script>
import moment from "moment-timezone";
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapActions } from "vuex";
import get from 'lodash/get'

export default {
mixins: [HelperMixin],
    data() {
    return {
      show: false,
      lastNote: null
    }
  },
    props: {
    patient: null
},
computed: {
  patientSubtitleLine() {
      // TODO: Refactor
      let shortGender = this.patient.gender.charAt(0)
      let displayBirthdate = null
      let age = null
      if (this.patient.birthdate) {
        // change birthdate into display birthdate
        displayBirthdate =
          this.patient.birthdate.substring(5, 7) +
          '/' +
          this.patient.birthdate.substring(8, 10) +
          '/' +
          this.patient.birthdate.substring(0, 4)

        let constructedDateTimestamp = new Date(
          this.patient.birthdate.substring(0, 4),
          this.patient.birthdate.substring(5, 7) - 1,
          this.patient.birthdate.substring(8, 10),
          0,
          0,
          0,
          0
        ).getTime()

        let ageDifMs = Date.now() - constructedDateTimestamp
        let ageDate = new Date(ageDifMs) // miliseconds from epoch
        age = Math.abs(ageDate.getUTCFullYear() - 1970)
      }
      return (
        age +
        ' ' +
        shortGender +
        ' · ' +
        displayBirthdate +
        ' · ' +
        get(this.patient, 'ownerPatientID', 'No Patient ID')
      )
    },
    patientNavigationList() {
      let chartSections = []


    //   let patientChart = {icon: 'mdi-chart-areaspline', title: 'Patient Monitoring', tooltip: 'Go to Chart', destinationName: 'Patient'}
    //   chartSections.push(patientChart)

      // let chartShares = {icon: 'mdi-account-multiple', title: 'Care Team', tooltip: 'Care Team', destinationName: 'Followers'}
      // chartSections.push(chartShares)

    //   let exportFacesheet = {icon: 'mdi-cloud-print-outline', title: 'Export Facesheet', tooltip: 'Export Facesheet', actionName: 'exportFacesheet'}
    //   chartSections.push(exportFacesheet)


      let patientChartNotes = {icon: 'mdi-file-document-box-plus-outline', title: 'Chart Notes', tooltip: 'Add a Note', actionName: 'note'}
      chartSections.push(patientChartNotes)

      let patientMessages = {icon: 'mdi-message-text-outline', title: 'Messages', tooltip: 'Send a Message', destinationName: 'Messages'}
      chartSections.push(patientMessages)

      let telehealthRoom = {icon: 'mdi-message-video', title: 'Create Telehealth Room', tooltip: 'Open Telehealth Room', actionName: 'telehealth'}
      chartSections.push(telehealthRoom)

      return chartSections
    }
},
methods: {
    ...mapActions('notes', ['getJustTheLastNote']),
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format("MM/DD/YYYY h:mm a");
        if (dt.includes("12:00 am")) {
          return "Today";
        } else {
          return moment(value).tz(moment.tz.guess()).fromNow();
        }
      }
      return '-';
    },
    relativeTime2(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value;

      let days = Difference_In_Time / (3600000 * 24);
      
      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 90) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format("MM/DD/YYYY h:mm a");
        if (dt.includes("12:00 am")) {
          return "Today";
        } else {
          return moment(value).tz(moment.tz.guess()).calendar();
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return "";
    },
    navigateToPatientFeed() {
      this.$router.push({ name: "PatientFeed", params: { id: this.item._id } });
    },
    navigateToPatientChart(destination) {
      if (destination.destinationName) {
        this.$router.push({
        name: destination.destinationName,
        params: { id: this.item._id },
      });
      }
    },
},
mounted() {
    this.show = true

    
}

}
</script>

<style>

</style>