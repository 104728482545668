<template>
  <v-toolbar-items>
    <v-btn @click="goToInfo()" :color="infoButtonColor" text>Info</v-btn>
    <v-btn @click="goToFeed()" :color="feedButtonColor" text>Activity</v-btn>

    <v-btn v-show="false" @click="goToNotes()" :color="notesButtonColor" text>Care Plan</v-btn>

    <v-btn v-show="false" @click="goToFiles()" :color="filesButtonColor" text>
      <v-badge :value="newShared" :content="'new'" dot>
        <span>Shared Files</span>
      </v-badge>
    </v-btn>
    <v-btn @click="goToMessaging()" :color="messagingButtonColor" text>Messaging</v-btn>
    <v-btn @click="goToDevices()" :color="devicesbuttonColor" text>Devices</v-btn>
    <v-btn v-show="false" @click="goToCareTeam()" :color="careTeamButtonColor" text>Care Team</v-btn>
    <v-btn v-show="false" @click="goToVideo()" :color="videoButtonColor" text>Telehealth</v-btn>
  </v-toolbar-items>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      newShared: true,
    }
  },
  methods: {
    goToInfo() {
      if (this.$route.name !== 'PatientInfo') {
        this.$router.push({
          name: 'PatientInfo',
          params: { id: this.$route.params.id },
        })
      }
    },
    goToFeed() {
      if (this.$route.name !== 'PatientFeed') {
        this.$router.push({
          name: 'PatientFeed',
          params: { id: this.$route.params.id },
        })
      }
    },
    goToNotes() {
      if (this.$route.name !== 'ChartNotes') {
        this.$router.push({
          name: 'ChartNotes',
          params: { id: this.$route.params.id },
        })
      }
    },
    goToMessaging() {
      if (this.$route.name !== 'Messages') {
        this.$router.push({
          name: 'Messages',
          params: { id: this.$route.params.id },
        })
      }
    },
    goToDevices() {
      if (this.$route.name !== 'Devices') {
        this.$router.push({
          name: 'Devices',
          params: { id: this.$route.params.id },
        })
      }
    },
    goToCareTeam() {
      this.$router.push({
        name: 'Followers',
        params: { id: this.$route.params.id },
      })
    },
    goToFiles() {
      this.newShared = false
      if (this.$route.name !== 'PatientFiles') {
        this.$router.push({
          name: 'PatientFiles',
          params: { id: this.$route.params.id },
        })
      }
    },
    goToVideo() {
      if (this.$route.name !== 'PatientVideo') {
        this.$router.push({
          name: 'PatientVideo',
          params: { id: this.$route.params.id },
        })
      }
    },
  },
  computed: {
    ...mapState('featureflags', ['showChartTabs']),
    infoButtonColor() {
      if (this.$route.name === 'PatientInfo') {
        return 'primary'
      }
      return 'grey darken-2'
    },
    feedButtonColor() {
      if (this.$route.name === 'PatientFeed') {
        return 'primary'
      }
      return 'grey darken-2'
    },
    notesButtonColor() {
      if (this.$route.name === 'ChartNotes') {
        return 'primary'
      }
      return 'grey darken-2'
    },
    careTeamButtonColor() {
      if (this.$route.name === 'Followers') {
        return 'primary'
      }
      return 'grey darken-2'
    },
    filesButtonColor() {
      if (this.$route.name === 'PatientFiles') {
        return 'primary'
      }
      return 'grey darken-2'
    },
    messagingButtonColor() {
      if (this.$route.name === 'Messages') {
        return 'primary'
      }
      return 'grey darken-2'
    },
    devicesbuttonColor() {
      if (this.$route.name === 'Devices') {
        return 'primary'
      }
      return 'grey darken-2'
    },
    videoButtonColor() {
      if (this.$route.name === 'PatientVideo') {
        return 'primary'
      }
      return 'grey darken-2'
    },
  },
}
</script>

<style></style>
