<template>
  <v-snackbar class="notification" timeout="-1" left bottom v-model="showSnackbar">
    <v-row no-gutters style="width: 300px">
      <v-col cols="2">
        <v-row no-gutters style="height: 100%">
          <v-icon class="mr-3" large>mdi-phone</v-icon>
          <v-divider vertical />
        </v-row>
        <v-spacer />
      </v-col>

      <v-col cols="10">
        <div v-if="patient"
          class="ml-4"
        >A mobile number is required to send a message to {{patient.firstName}} {{patient.lastName}}.</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-3 pl-4" align="center">
      <!-- 1 of 1 -->
      <v-spacer />
      <v-btn class="mr-2" dark small depressed text @click="showSnackbar = false">Dismiss</v-btn>
      <v-btn
        small
        depressed
        :to="{name: 'PatientInfo', params:$route.params}"
        @click="showSnackbar = false"
      >Add Phone</v-btn>
    </v-row>
  </v-snackbar>
</template>

<script>
import bus from '@/core/helpers/bus'

export default {
  data: () => {
    return {
      patient: null,
      showSnackbar: false,
    }
  },
  methods: {
    handleShow(patient = {}) {
      this.patient = patient
      this.showSnackbar = true
    }
  },
  created() {
    bus.$on('smssnack:show', this.handleShow)
  },
  destroyed() {
    bus.$off('smssnack:show', this.handleShow)
  }
}
</script>