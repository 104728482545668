<template>
  <v-scale-transition origin="center">
    <v-hover v-show="triggered" v-slot="{ hover }">
      <v-card
        rounded="lg"
        style="overflow:hidden; cursor:move; cursor:-webkit-grab; cursor:-moz-grab; cursor:grab;"
        id="telehealthWindow"
        dark
        class="video-popup elevation-8"
        :width="cardWidth"
        :max-width="cardWidth"
        min-height="100"
        color="black"
      >
        <v-divider dark />

        <v-card min-height="100" color="black">
          <v-scale-transition>
            <v-overlay absolute :value="creatingRoom" color="black">
              <v-progress-circular width="3" indeterminate size="30">
              </v-progress-circular>
            </v-overlay>
          </v-scale-transition>

          <video-room
            v-if="activeVideoCallToken"
            :roomToken="activeVideoCallToken"
            :inviteeURL="activeVideoCallInviteeLink"
            :inviteeToken="activeInviteeToken"
            :miniView="miniView"
            @sessionDocumentation="sessionDocumentation"
            @portraitOrientation="landscape = false"
            @landscapeOrientation="landscape = true"
            @roomClosed="handleClose"
            @roomConnected="creatingRoom = false"
            @inviteeConnected="inviteeConnectedStatus"
            @link:click="linkUrl => $emit('link:click', linkUrl)"
          />
          <v-fade-transition>
            <v-row
              v-show="hover"
              style="cursor:move; cursor:-webkit-grab; cursor:-moz-grab; cursor:grab; position: absolute; top: 0; width: 100%;"
              align="center"
              class="pa-2 px-3 py-3"
              no-gutters
              justify="end"
            >
              <v-spacer />

              <portal-target name="videoPopupTimerSlot" slim />

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="inviteeConnected"
                    v-bind="attrs"
                    v-on="on"
                    @click="miniView = !miniView"
                    icon
                    style="background-color: #00000030"
                    small
                  >
                    <v-scale-transition origin="center" hide-on-leave>
                      <v-icon small v-show="miniView">
                        mdi-arrow-expand
                      </v-icon>
                    </v-scale-transition>
                    <v-scale-transition origin="center" hide-on-leave>
                      <v-icon small v-show="!miniView">
                        mdi-arrow-collapse
                      </v-icon>
                    </v-scale-transition>
                  </v-btn>
                </template>
                <span v-if="miniView">Expand</span>
                <span v-else>Collapse</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="creatingRoom"
                    small
                    style="background-color: #00000030"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleClose()"
                    icon
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </template>
                Close
              </v-tooltip>
            </v-row>
          </v-fade-transition>
        </v-card>
      </v-card>
    </v-hover>
  </v-scale-transition>
</template>

<style lang="scss" scoped>
.video-popup {
  position: fixed;
  top: 80px;
  right: 10px;
  z-index: 20;
}
.video-small {
  transform: scaleX(0.5), scaleY(0.5);
}
.v-icon.active {
  transform: rotate(-180deg);
}
</style>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import VideoRoom from '../videoroom/VideoRoom.vue'
import bus from '@/core/helpers/bus'

export default {
  components: {
    VideoRoom,
  },
  data() {
    return {
      miniView: false,
      landscape: false,
      creatingRoom: false,
      triggered: false,
      inviteeConnected: false,
      sessions: [],
      openSession: null,
      patientParticipant: null,
    }
  },
  watch: {
    miniView(val) {
      if (val) {
        document.getElementById('telehealthWindow').style.left = null
        document.getElementById('telehealthWindow').style.bottom = null
        document.getElementById('telehealthWindow').style.right = '10px'
        document.getElementById('telehealthWindow').style.top = '80px'
      }
    },
    cardWidth() {
      this.$nextTick(() => this.evaluatePosition())

      // make sure an orientation change doesnt move this off the page

      //   check to see if the right margin is negative?
      //   console.log('landscape has changed')
      //       console.log('card width has changed')

      //         let elem = document.getElementById('telehealthWindow');
      // let bounding = elem.getBoundingClientRect();

      // if (bounding.top < 0) {
      // 	// Top is out of viewport
      //   elem.style.bottom = null
      //   elem.style.top = '80px'

      // }

      // if (bounding.left < 0) {
      // 	// Left side is out of viewoprt
      //   elem.style.right = null
      //   elem.style.left = '10px'

      // }

      // if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
      // 	// Bottom is out of viewport
      //   elem.style.top = null
      //   elem.style.bottom = '10px'

      // }

      // if (bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
      // 	// Right side is out of viewport
      //   console.log('bounding reck is off the side to the right')
      //   elem.style.left = null
      //   elem.style.right = '10px'

      // }
    },
    triggered(val) {
      if (val) {
        this.clearVideoRoomInformation()
        this.landscape = false
        this.miniView = false
        this.sessions = []
        document.getElementById('telehealthWindow').style.left = null
        document.getElementById('telehealthWindow').style.bottom = null
        document.getElementById('telehealthWindow').style.right = '10px'
        document.getElementById('telehealthWindow').style.top = '80px'
        // this.height = 300
        this.creatingRoom = true
        this.generateVideoRoom()
      } else {
        // this was triggered from outside. maybe by navigating away from the page
        this.creatingRoom = false
        this.handleClose()
      }
    },
    inviteeConnected(val) {
      if (!val) {
        this.landscape = false
        this.miniView = false
        this.openSession.end = new Date().getTime()
        this.sessions.push({
          start: this.openSession.start,
          end: this.openSession.end,
        })
        this.openSession = null
      } else {
        let start = new Date().getTime()
        this.openSession = { start: start }
      }
    },
  },
  computed: {
    ...mapState('telehealth', [
      'activeVideoCall',
      'activeVideoCallToken',
      'activeVideoCallInviteeLink',
      'activeInviteeToken',
    ]),
    ...mapState('auth', ['user', 'partner']),
    landscapeWidth() {
      if (this.miniView) {
        return 180
      } else {
        return 600
      }
    },
    portraitWidth() {
      if (this.miniView) {
        return 120
      } else {
        return 360
      }
    },
    cardWidth() {
      if (this.landscape) {
        return this.landscapeWidth
      } else {
        return this.portraitWidth
      }
    },
  },
  methods: {
    ...mapActions('auth', ['addChartNote']),
    ...mapActions('telehealth', [
      'generateVideoRoom',
      'clearVideoRoomInformation',
      'documentTelehealthSession',
    ]),
    ...mapMutations('telehealth', ['setActiveVideoCall']),
    inviteeConnectedStatus(connectionStatus) {
      this.inviteeConnected = connectionStatus
    },
    evaluatePosition() {
      let elmnt = document.getElementById('telehealthWindow')
      let bounding = elmnt.getBoundingClientRect()

      let offScreenToRight =
        elmnt.offsetLeft + elmnt.offsetWidth > document.body.clientWidth - 10

      let offScreenToBottom =
        elmnt.offsetTop + elmnt.offsetHeight > window.innerHeight - 10

      let offScreenToLeft = elmnt.offsetLeft < 0

      let offScreenToTop = elmnt.offsetTop < 0

      if (offScreenToRight) {
        document.getElementById('telehealthWindow').style.left =
          document.body.clientWidth - 10 - bounding.width + 'px'
      }

      if (offScreenToBottom) {
        document.getElementById('telehealthWindow').style.top =
          window.innerHeight - 10 - bounding.height + 'px'
      }

      if (offScreenToLeft) {
        document.getElementById('telehealthWindow').style.left = '10px'
      }

      if (offScreenToTop) {
        document.getElementById('telehealthWindow').style.top = '10px'
      }
    },
    sessionDocumentation() {
      if (this.sessions.length > 0 || (this.openSession && this.user)) {
        console.log('there is just ' + this.sessions.length)
        console.log(this.sessions)

        if (this.openSession) {
          this.openSession.end = new Date().getTime()
          this.sessions.push({
            start: this.openSession.start,
            end: this.openSession.end,
          })
          this.openSession = null
        }

        console.log('now there is just ' + this.sessions.length)
        console.log(this.sessions)

        let length = 0
        let firstStart = this.sessions[0].start

        this.sessions.forEach(session => {
          if (session.start && session.end) {
            let sessionLength = session.end - session.start
            length = length + sessionLength
          }
        })
        // this was a successful video call. you should log this session.

        let videoDuration = length

        if (videoDuration > 0) {
          let participant = this.user._id

          let noteInfo = {
            videoStart: firstStart,
            videoDuration: videoDuration,
            user: participant,
          }

          this.addChartNote({
            patient: this.patientParticipant,
            partner: this.partner._id,
            sessionNote: 'Telehealth Visit',
            type: 'auto',
            action: 'add',
            referenceData: { type: 'telehealth', data: noteInfo },
          }).then(() => {
            if (this.$route.params.id) {
            bus.$emit('refreshNoteList')
            }
          })
        }
        this.sessions = []
      }
    },
    handleClose() {
      this.clearVideoRoomInformation()
      if (this.sessions.length > 0 || this.openSession) {
        this.sessionDocumentation()
      }
      if (!this.miniView) {
        let vm = this
        this.$nextTick().then(() => {
          vm.miniView = true
          setTimeout(() => {
            vm.triggered = false
          }, 90)
        })
      } else {
        this.triggered = false
      }
    },
  },
  mounted() {
    bus.$on('video:open', payload => {
      this.triggered = true
      this.patientParticipant = payload.patientID
    })
    dragElement(document.getElementById('telehealthWindow'))

    function dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0
      if (document.getElementById(elmnt.id + 'header')) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown
      }

      function dragMouseDown(e) {
        e = e || window.event
        e.preventDefault()
        // get the mouse cursor position at startup:
        pos3 = e.clientX
        pos4 = e.clientY
        document.onmouseup = closeDragElement
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag
      }

      function elementDrag(e) {
        e = e || window.event
        e.preventDefault()
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX
        pos2 = pos4 - e.clientY
        pos3 = e.clientX
        pos4 = e.clientY
        // set the element's new position:
        // let offScreenToRight =
        //   elmnt.offsetLeft - pos1 + elmnt.offsetWidth >
        //   document.body.clientWidth - 4
        // let offScreenToBottom =
        //   elmnt.offsetTop - pos2 + elmnt.offsetHeight > window.innerHeight - 4

        // if (
        //   elmnt.offsetTop - pos2 > 2 &&
        //   elmnt.offsetLeft - pos1 > 2 &&
        //   !offScreenToRight &&
        //   !offScreenToBottom
        // ) {

        // }

        elmnt.style.top = elmnt.offsetTop - pos2 + 'px'
        elmnt.style.left = elmnt.offsetLeft - pos1 + 'px'
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null
        document.onmousemove = null

        let elmnt = document.getElementById('telehealthWindow')
        let bounding = elmnt.getBoundingClientRect()

        let offScreenToRight =
          elmnt.offsetLeft + elmnt.offsetWidth > document.body.clientWidth - 10

        let offScreenToBottom =
          elmnt.offsetTop + elmnt.offsetHeight > window.innerHeight - 10

        let offScreenToLeft = elmnt.offsetLeft < 0

        let offScreenToTop = elmnt.offsetTop < 0

        if (offScreenToRight) {
          document.getElementById('telehealthWindow').style.left =
            document.body.clientWidth - 10 - bounding.width + 'px'
        }

        if (offScreenToBottom) {
          document.getElementById('telehealthWindow').style.top =
            window.innerHeight - 10 - bounding.height + 'px'
        }

        if (offScreenToLeft) {
          document.getElementById('telehealthWindow').style.left = '10px'
        }

        if (offScreenToTop) {
          document.getElementById('telehealthWindow').style.top = '10px'
        }
      }
    }
  },
  beforeDestroy() {
    this.clearVideoRoomInformation()
    if (this.sessions.length > 0 || this.openSession) {
      this.sessionDocumentation()
    }
  },
}
</script>

<style></style>
