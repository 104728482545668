<template>
    
    <v-menu transition="slide-y-transition" :position-y="70" v-model="menu" :close-on-click="false" :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn class="mx-1" icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>mdi-message-video</v-icon>
              </v-btn>
            </template>
            <span>Start Telehealth Session</span>
          </v-tooltip>
        </template>

      <v-card color="white" max-width="400">
        <v-text-field
      hidden
      ref="textToCopy"
      v-model="activeVideoCallInviteeLink"
      style="width:0px; max-width:0px; position:absolute;"
    ></v-text-field>
        
          <v-row no-gutters v-if="user && user.partnerID" class="mx-0 flex" align="center" justify="center" >
            <v-card min-width="350" tile flat color="black" @click="openRoom()" height="150" style="width:100%">
              <v-layout fill-height align-center justify-center>
                <v-scale-transition leave-absolute hide-on-leave  origin="center center">
            <div style="color: white; text-align: center" v-show="activeVideoCallInviteeLink && !activeVideoCall">
              <div style="color: white;">Telehealth Room Created</div>
              <div style="color: white; font-size:20px; font-weight:700">{{activeVideoCallInviteeLink}}</div>
            </div>
          </v-scale-transition>

          <v-scale-transition leave-absolute hide-on-leave  origin="center center">
            <span style="color: white"  v-show="activeVideoCallInviteeLink && activeVideoCall">Active Video Call</span>
          </v-scale-transition>

          <v-scale-transition leave-absolute hide-on-leave origin="center center">
            <v-progress-circular
              size="30"
              width="3"
              v-show="!activeVideoCallInviteeLink"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-scale-transition>
              </v-layout>
            </v-card>
              
          
          </v-row>

          <v-toolbar style="position:absolute; top:0; left:0; width:100%; " color="transparent" dense>
          <v-spacer/>
          <v-btn @click="closeRoom()" dark icon><v-icon>mdi-close</v-icon></v-btn>

        </v-toolbar>

          <v-list-item @click="openRoom()">
  <v-row justify-center no-gutters justify="center"><div class="ml-2"><v-list-item-title>Go to Room</v-list-item-title></div>
        <!-- <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          
        </v-list-item-content> -->
  </v-row>
      </v-list-item>
              <v-divider/>


<v-list-item @click="sendJoiningInfoByText()">
  <v-row justify-center no-gutters justify="center"><div><v-list-item-title>Send Link By Text</v-list-item-title></div>
        <!-- <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          
        </v-list-item-content> -->
  </v-row>
      </v-list-item>
              <v-divider/>

<v-list-item @click="copyJoiningInformation()">
  <v-row justify-center no-gutters justify="center"><div><v-list-item-title>Copy Room Link</v-list-item-title></div>
        <!-- <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          
        </v-list-item-content> -->
  </v-row>
      </v-list-item>
              <v-divider/>
<v-list-item color="" @click="closeRoom()">
  <v-row justify-center no-gutters justify="center"><div><v-list-item-title>Close Room</v-list-item-title></div>
        <!-- <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          
        </v-list-item-content> -->
  </v-row>
      </v-list-item>
      </v-card>
    </v-menu>
</template>

<style lang="scss">
.managedByChip {
    padding:4px; border-radius:3px; width:100%; text-align:center; font-size:14px; background-color:#E3F2FD;
}
.nameStyle {
  font-size: 18px;
}
.emailStyle {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import bus from "@/core/helpers/bus";

export default {
  data() {
    return {
      rooms: [],
      currentlyTrackedPatient: null,
      isTracking: false,
      rpmInterval: null,
      clockInterval: null,
      startTimeForSession: null,
      elapsedTime: null,
      fav: true,
      menu: false,
      sessionNotes: null,
      refPoint: null,
      menuKey: 0,
      closeOnClick: false,
      savingNote: false,
      child: null,
    };
  },
  components: {},
  props: {
    enablePopups: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    ...mapState("auth", ["user", "configuration", "partner", "appTheme"]),
    ...mapState("patient", [
      "patient",
      "activeVideoCall",
      "activeVideoCallSelfLink",
      "activeVideoCallInviteeLink",
    ]),
    cancelText() {
      if (this.activeVideoCall) {
        return "Close";
      }
      if (this.menu) {
        return "Cancel";
      }
      return "Cancel";
    },
    buttonTitle() {
      if (this.activeVideoCall) {
        return "Active Room";
      }
      if (this.menu && !this.activeVideoCallInviteeLink) {
        return "Creating Room";
      }

      if (this.menu && this.activeVideoCallInviteeLink) {
        return "Go to Room";
      }

      return "Video";
    },
    buttonIcon() {
      if (this.activeVideoCall) {
        return "mdi-video-check";
      }
      if (this.menu) {
        return "mdi-video-check";
      }
      return "mdi-video-plus";
    },
    refValue() {
      console.log(this.refPoint);
      if (this.refPoint) {
        return this.refPoint.value;
      }
      return "val";
    },
    refUnits() {
      return "face";
    },
    partnerDisplayName() {
      if (this.partner && this.partner.name) {
        return this.partner.name;
      } else if (this.user && this.user.partnerID) {
        return this.user.partnerID;
      }
      return "Partner";
    },
    trackerIcon() {
      if (this.isTracking) {
        return "mdi-stop";
      }
      return "mdi-clock-outline";
    },
    buttonColor() {
      if (!this.activeVideoCall) {
        return "gray";
      }
      return "primary";
    },
    menuColor() {
      if (!this.activeVideoCall) {
        return "gray";
      }
      return "#f5f5f5";
    },
  },
  watch: {
    activeVideoCallInviteeLink(val) {
      if (val) {
        bus.$emit("toast", { type: "success", text: "New Video Room Created" });
      }
    },
    menu(val) {
      if (val && !this.activeVideoCall) {
        // go get rooms
        this.generateVideoRoom();
      } else {
        // do nothing, just open the menu
        this.closeRoom()
      }
    },
  },
  methods: {
    ...mapActions("auth", [
      "rpmTrack",
      "rpmClear",
      "userAction",
      "addChartNote",
    ]),
    ...mapActions("patient", ["getConferenceRooms", "generateVideoRoom"]),
    ...mapMutations("patient", ["setActiveVideoCall"]),
    toggleMenu() {
      this.menu = !this.menu;
    },
    copyJoiningInformation() {
      console.log("youre copying the joining info");
      console.log(this.activeVideoCallInviteeLink);

      navigator.clipboard
        .writeText(this.activeVideoCallInviteeLink)
        .then(() => {
          bus.$emit("toast", { type: "success", text: "Copied to clipboard" });
        })
        .catch((error) => {
          bus.$emit("toast", {
            type: "success",
            text: `Copy failed! ${error}`,
          });
        });
    },
    sendJoiningInfoByText() {
      if (this.$route.name === 'Messages') {
        bus.$emit("sendMessageToActivePatient", {
        text:
          "Please join this telehealth video call using the following link: " +
          this.activeVideoCallInviteeLink,
      });
      } else {
        this.$router.push({
        name: "Messages",
        params: { id: this.patient._id },
      }).then(() => {
        bus.$emit("sendMessageToActivePatient", {
        text:
          "Please join this telehealth video call using the following link: " +
          this.activeVideoCallInviteeLink,
      });
      })
      }

      
      
    },
    openMenu() {
      this.generateVideoRoom().then((data) => {
        console.log(data);
        this.$refs.menuOptions.open();
      });
    },
    toggleRoom() {
      if (this.activeVideoCall) {
        this.closeRoom();
      } else {
        this.openRoom();
      }
    },
    openRoom() {
      if (!this.activeVideoCall) {
        var winFeature =
          "location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes;";
        this.isTracking = true;
        this.child = window.open(
          this.activeVideoCallSelfLink,
          "null",
          winFeature
        );
        this.setActiveVideoCall(true);
        let vm = this;
        var interval = setInterval(function () {
          try {
            if (this.child.document.domain === document.domain) {
              console.log("same domain");
            } else {
              // this code should never be reached,
              // as the x-site security check throws
              // but just in case
              console.log("new domain");
            }
          } catch (e) {
            // we're here when the child window has been navigated away or closed
            if (vm.child.closed) {
              bus.$emit("toast", { type: "close", text: "Video Room Closed" });
              vm.setActiveVideoCall(false);
              vm.menu = false;
              vm.isTracking = false;
              clearInterval(interval);
              return;
            }
          }
        }, 500);
      } else {
        this.child.focus();
      }
    },
    closeRoom() {
      bus.$emit("toast", { type: "close", text: "Video Room Closed" });
      this.setActiveVideoCall(false);
      this.menu = false;
      this.child.close();
    },
    setRPMTimer() {
      let self = this;
      this.rpmInterval = setInterval(function () {
        self.rpmTrack({ patient: self.$route.params.id }).then(() => {
        });
      }, 30000);
    },
    setClock() {
      let self = this;
      this.clockInterval = setInterval(function () {
        if (self.startTimeForSession) {
          let data = Math.trunc(
            new Date().getTime() / 1000 - self.startTimeForSession / 1000
          );
          let hours = Math.floor(data / 3600);
          let minutes = Math.floor((data - hours * 3600) / 60) + hours * 60;
          let seconds = data - minutes * 60;

          let timeString =
            minutes.toString().padStart(2, "0") +
            ":" +
            seconds.toString().padStart(2, "0");
          self.elapsedTime = timeString;
        }
      }, 1000);
    },
    toggleTime() {
      this.isTracking = !this.isTracking;
      if (this.isTracking) {
        this.startTrackingTime();
      } else {
        this.endTrackingTime();
      }
    },
    startTrackingTime() {
      this.elapsedTime = "00:00";
    },
    endTrackingTime() {
      this.startTimeForSession = null;
      clearInterval(this.rpmInterval);
      console.log("endTime time");
    },
    saveSessionNotes() {
      if (this.sessionNotes) {
        this.savingNote = true;
        this.addChartNote({
          patient: this.$route.params.id,
          partner: this.partner._id,
          sessionNote: this.sessionNotes,
          referenceData: this.refPoint,
        }).then(() => {
          bus.$emit("refreshNoteList");
          bus.$emit("toast", { type: "success", text: "Note Saved" });
          this.menu = false;
          this.closeOnClick = true;
          this.sessionNotes = null;
          this.refPoint = null;
          this.savingNote = false;
        });
      } else {
        this.menu = false;
        this.closeOnClick = true;
      }
    },
  },
  mounted() {
if (this.enablePopups) {
    bus.$on("openVideo", () => {
      if (!this.menu) {
        // open the note with the information in the payload
        
        this.menu = true;
      }
    });
}

    this.setClock();
  },
  beforeDestroy() {},
};
</script>

<style>
</style>