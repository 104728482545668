<template>

<v-dialog
        v-model="menu"
        width="500"
        scrollable
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon class="mt-n1">mdi-account-plus</v-icon>
              </v-btn>
            </template>
            <span>Share Chart</span>
          </v-tooltip>
        </template>
  
       <share-chart :menuOpened="menu" :patient="patient" @cancel="menu = false"/>
      </v-dialog>

</template>

<script>
import HelperMixin from "@/core/mixins/HelperMixin";
import ShareChart from './ShareChart.vue';

export default {
    mixins: [HelperMixin],
  name: "addChartNoteMenu",
  components: {
    ShareChart,
  },
  props: {
    enablePopups: {
      type: Boolean,
      default: true
    },
    patient: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      menu: false,
      refPoint: null,
      taggedUser: null
    }
  },
  watch: {
    menu(val) {
      if (!val) {
        this.refPoint = null
        this.taggedUser = null
      }
    }
  },
  mounted() {

  },

}
</script>

<style>

</style>