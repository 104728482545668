<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        rounded
        :disabled="!userCanTrackTime"
        :color="buttonColor"
        :dark="isTracking"
        @click="toggleTime"
        depressed
        :text="!isTracking"
      >
        <v-icon left class="mr-2">{{ trackerIcon }}</v-icon>
        {{ trackerTitle }}
      </v-btn>
    </template>
    <span>{{ timerTooltip }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import get from 'lodash/get'

export default {
  data() {
    return {
      currentlyTrackedPatient: null,
      isTracking: false,
      rpmInterval: null,
      clockInterval: null,
      startTimeForSession: null,
      elapsedTime: null,
      fav: true,
      menu: false,
      sessionNotes: null,
      refPoint: null,
      menuKey: 0,
      closeOnClick: false,
      savingNote: false,
    }
  },
  components: {},
  computed: {
    ...mapState('auth', ['user', 'configuration', 'partner', 'appTheme']),
    ...mapState('patient', ['patient']),
    userCanTrackTime() {
      if (
        get(this, 'patient.rpm.enrollment.enrolled', false) &&
        get(this, 'user.partnerRoles.qhp', false)
      ) {
        return true
      }
      return false
    },
    timerTooltip() {
      if (this.isTracking) {
        return 'Stop Timer'
      }
      return 'Start Timer'
    },
    trackerTitle() {
      if (this.isTracking) {
        if (this.elapsedTime) {
          return this.elapsedTime
        } else {
          return 'time error'
        }
      }
      return '00:00'
    },
    trackerIcon() {
      if (this.isTracking) {
        return 'mdi-stop'
      }
      return 'mdi-clock-outline'
    },
    buttonColor() {
      if (!this.isTracking) {
        return 'grey'
      }
      return 'green'
    },
  },
  watch: {
    menu(val) {
      if (!val) {
        this.sessionNotes = null
        this.refPoint = null
        this.closeOnClick = true
      } else {
        setTimeout(() => this.$refs.addNoteCard.focusOnNote(), 300)
      }
    },
  },
  methods: {
    ...mapActions('auth', [
      'rpmTrack',
      'rpmClear',
      'userAction',
      'addChartNote',
    ]),
    setRPMTimer() {
      let self = this
      this.rpmInterval = setInterval(function() {
        self.rpmTrack({ patient: self.$route.params.id }).then(() => {})
      }, 30000)
    },
    setClock() {
      let self = this
      this.clockInterval = setInterval(function() {
        if (self.startTimeForSession) {
          let data = Math.trunc(
            new Date().getTime() / 1000 - self.startTimeForSession / 1000
          )
          let hours = Math.floor(data / 3600)
          let minutes = Math.floor((data - hours * 3600) / 60) + hours * 60
          let seconds = data - minutes * 60

          let timeString =
            minutes.toString().padStart(2, '0') +
            ':' +
            seconds.toString().padStart(2, '0')
          self.elapsedTime = timeString
        }
      }, 1000)
    },
    toggleTime() {
      if (this.userCanTrackTime) {
        this.isTracking = !this.isTracking
        if (this.isTracking) {
          this.startTrackingTime()
        } else {
          this.endTrackingTime()
          bus.$emit('toast', { type: 'error', text: 'Timer Stopped' })
        }
      }
    },
    startTrackingTime() {
      this.elapsedTime = '00:00'
      this.startTimeForSession = new Date().getTime()
      this.rpmTrack({ patient: this.$route.params.id })
      this.currentlyTrackedPatient = this.$route.params.id
      this.setRPMTimer()
    },
    endTrackingTime() {
      this.startTimeForSession = null
      clearInterval(this.rpmInterval)
      this.rpmTrack({ patient: this.$route.params.id })
      this.currentlyTrackedPatient = null
      this.rpmClear()
      console.log('endTime time')
    },
  },
  mounted() {
    this.setClock()
    if (this.configuration.autoCounting) {
      this.toggleTime()
    }
  },
  beforeDestroy() {
    if (this.isTracking) {
      this.rpmTrack({ patient: this.currentlyTrackedPatient })
    }
    this.rpmClear()
    clearInterval(this.rpmInterval)
    clearInterval(this.clockInterval)
  },
}
</script>

<style></style>
