var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scale-transition',{attrs:{"origin":"center"}},[_c('v-hover',{directives:[{name:"show",rawName:"v-show",value:(_vm.triggered),expression:"triggered"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"video-popup elevation-8",staticStyle:{"overflow":"hidden","cursor":"grab"},attrs:{"rounded":"lg","id":"telehealthWindow","dark":"","width":_vm.cardWidth,"max-width":_vm.cardWidth,"min-height":"100","color":"black"}},[_c('v-divider',{attrs:{"dark":""}}),_c('v-card',{attrs:{"min-height":"100","color":"black"}},[_c('v-scale-transition',[_c('v-overlay',{attrs:{"absolute":"","value":_vm.creatingRoom,"color":"black"}},[_c('v-progress-circular',{attrs:{"width":"3","indeterminate":"","size":"30"}})],1)],1),(_vm.activeVideoCallToken)?_c('video-room',{attrs:{"roomToken":_vm.activeVideoCallToken,"inviteeURL":_vm.activeVideoCallInviteeLink,"inviteeToken":_vm.activeInviteeToken,"miniView":_vm.miniView},on:{"sessionDocumentation":_vm.sessionDocumentation,"portraitOrientation":function($event){_vm.landscape = false},"landscapeOrientation":function($event){_vm.landscape = true},"roomClosed":_vm.handleClose,"roomConnected":function($event){_vm.creatingRoom = false},"inviteeConnected":_vm.inviteeConnectedStatus,"link:click":function (linkUrl) { return _vm.$emit('link:click', linkUrl); }}}):_vm._e(),_c('v-fade-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"pa-2 px-3 py-3",staticStyle:{"cursor":"grab","position":"absolute","top":"0","width":"100%"},attrs:{"align":"center","no-gutters":"","justify":"end"}},[_c('v-spacer'),_c('portal-target',{attrs:{"name":"videoPopupTimerSlot","slim":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.inviteeConnected),expression:"inviteeConnected"}],staticStyle:{"background-color":"#00000030"},attrs:{"icon":"","small":""},on:{"click":function($event){_vm.miniView = !_vm.miniView}}},'v-btn',attrs,false),on),[_c('v-scale-transition',{attrs:{"origin":"center","hide-on-leave":""}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.miniView),expression:"miniView"}],attrs:{"small":""}},[_vm._v(" mdi-arrow-expand ")])],1),_c('v-scale-transition',{attrs:{"origin":"center","hide-on-leave":""}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.miniView),expression:"!miniView"}],attrs:{"small":""}},[_vm._v(" mdi-arrow-collapse ")])],1)],1)]}}],null,true)},[(_vm.miniView)?_c('span',[_vm._v("Expand")]):_c('span',[_vm._v("Collapse")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.creatingRoom)?_c('v-btn',_vm._g(_vm._b({staticStyle:{"background-color":"#00000030"},attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.handleClose()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()]}}],null,true)},[_vm._v(" Close ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }