<template>
  <v-card class="pa-3 px-4">
    <v-row
      align="center"
      no-gutters
      justify="space-between"
      class="font-weight-bold text-h6"
    >
      <span>Share Chart</span>
      
      <v-btn color="primary" small text @click="info=!info">What is chart sharing?</v-btn>
    </v-row>

    <v-card-text class="pa-0">

      <v-expand-transition>
<div v-show="info">

        <v-row class="text-body-2 px-1 py-1" no-gutters>
          Sharing the chart will grant the recipient read-only access to a restricted view of the patient chart. The recipient will be able to view basic patient demographics, medications, conditions, and up to date device readings. Chart notes and messaging are not shared.
          For security, invitation emails will expire within 72 hours. 
        </v-row>

</div>
</v-expand-transition>

      <patient-summary-card class="mt-2" :patient="patient" />
      <v-form class="pb-5" ref="emailForm" v-model="valid">
        <v-text-field
        ref="emailInput"
          class="mt-3"
          flat
          v-model="emailAddress"
          required
          label="Enter Recipient Email Address.."
          :rules="emailRules"
          solo-inverted
          @keydown.enter.stop.prevent="addUser()"
          clearable
        ></v-text-field>

        <v-row class="text-subtitle-2 px-1" no-gutters>
          The following message will be sent to the recipient:
        </v-row>
        <div style=" position:relative;">
        <v-card
          style="overflow-y:scroll;"
          flat
          rounded="lg"
          height="140"
          color="grey lighten-4"
          class="mb-3 mt-1"
        >
        
          <v-card-text v-html="htmlForInserting"> </v-card-text>
          
        </v-card>
        <v-icon color="grey lighten-1" style="position:absolute; top:10px; right:10px;">mdi-lock</v-icon>
        </div>


        <v-row class="text-subtitle-2 px-1" no-gutters>
          Privacy Warning
        </v-row>

        <v-row no-gutters class="text-caption px-1 pt-1">
          <span>Patient health information
          is protected by federal and state privacy laws and is intended only
          for the use of authorized person(s). You are responsible for verifying
          that the recipient is authorized to view the shared information. Share
          only with trusted parties.
        </span>
        </v-row>


      </v-form>
    </v-card-text>

    <v-row class="mt-4" no-gutters>
      <v-btn depressed @click="$emit('cancel')">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" dark @click="addUser()"
        ><span v-show="!sharingChart">Send Invite</span>
        <v-progress-circular v-show="sharingChart" class="mx-6" size="20" indeterminate dark/>
        
        </v-btn
      >
    </v-row>
  </v-card>
</template>
<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import PatientSummaryCard from './PatientSummaryCard.vue'

export default {
  mixins: [DialogMixin, HelperMixin],
  components: { PatientSummaryCard },
  data() {
    return {
      sharingChart: false,
      info: false,
      emailAddress: '',
      valid: false,
      quickshare: false,
      chartViewer: true,
      chartAdministrator: false,
      nameRules: [v => !!v || 'Required'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => this.validateEmail(v) || 'E-mail must be valid',
      ],
      inviteBulletPoints: [
        {
          icon: 'mdi-email',
          title: 'Invitation Email',
          text: 'A secure invite link is sent to the indicated email address.',
        },
        {
          icon: 'mdi-lock',
          title: 'Authenticated Access',
          text:
            'The recipient must follow the link and sign in or sign up to be granted access. Only the indicated email can be used.',
        },
        {
          icon: 'mdi-calendar-clock',
          title: 'Link Expires in 72 Hours',
          text:
            'The secure link embedded in the email will expire within 72 hours if unused.',
        },
      ],
    }
  },
  props: {
    patient: {
      type: Object,
      default: null,
    },
    menuOpened: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    htmlForInserting() {
      let body =
        '<body><p>Welcome,</p><p>{{user_email}} has invited you to view medical records on NovoRPM. You may view these records securely by navigating to the following link:</p><p><strong>Temporary Access Link</strong></p><p>For security purposes, this link will expire in 72 hours.</p></body></html>'

      return body.replace('{{user_email}}', this.user.email)
    },
    transition() {
      return this.$vuetify.breakpoint.xsOnly
        ? 'dialog-bottom-transition'
        : 'scale-transition'
    },
    emailValid() {
      if (this.validateEmail(this.emailAddress)) {
        return true
      }
      return false
    },
  },
  watch: {
    menuOpened(val) {
      if (!val) {
        this.emailAddress = null
        this.sharingChart = null
        this.$refs.emailForm.reset()
      } else {
        console.log('should focus on note')
        this.$refs.emailForm.reset()
        let vm = this
        this.$nextTick(() => {
          vm.$refs.emailInput.focus()})
        
      }
    },
    quickshare(val) {
      if (val) {
        this.chartViewer = false
        this.chartAdministrator = false
      }
    },
    chartAdministrator(val) {
      if (val) {
        this.chartViewer = false
        this.quickshare = false
      }
    },
    chartViewer(val) {
      if (val) {
        this.quickshare = false
        this.chartAdministrator = false
      }
    },
  },
  methods: {
    ...mapActions('provider', ['shareChart']),
    ...mapActions('auth', ['userExists', 'addUserToPartner']),
    ...mapActions('patient', ['patientInvite', 'patientQuickshare']),
    toast(type, text) {
      bus.$emit('toast', { type, text })
    },
    cancelOption() {
      console.log('doing something')
      this.cancel()
    },
    checkUser() {},
    addUser() {
      let val = this.emailAddress
      val = val.trim()
      if (this.validateEmail(val)) {
        this.sharingChart = true
        if (this.quickshare) {
          console.log('boom you are doing a quickshare')
          this.patientQuickshare({
            patient: this.patient._id,
            email: this.emailAddress,
          })
            .then(data => {
              if (data.status === 201) {
                this.toast('success', 'Invite Sent')
                bus.$emit('refreshPatientInviteList')
              } else {
                this.toast('success', 'Invite Sent')
                bus.$emit('refreshPatientInviteList')
              }
              this.cancel()
            })
            .catch(error => {
              console.log(error)
              this.toast('error', 'Please try again later')
            })
        } else {
          let permissions = {}

          if (this.chartViewer) {
            permissions.viewer = true
          } else {
            permissions.admin = true
          }
          this.patientInvite({
            patient: this.patient._id,
            email: this.emailAddress,
            permissions: permissions,
          })
            .then(data => {
              this.sharingChart = false
              if (data.status === 204) {
                console.log('user has been added directly')
                this.toast('success', 'User Added')
                bus.$emit('refreshCollaboratorList')
              }
              if (data.status === 201) {
                console.log('an invite has been sent')
                this.toast('success', 'Invite Sent')
                bus.$emit('refreshPatientInviteList')
              }

              this.$emit('cancel')
            })
            .catch(error => {
              if (error.response.status === 409) {
                this.toast('success', 'User already associated with patient')
                this.$emit('cancel')
              } else {
                console.log(error)
                this.toast('error', 'Please try again later')
                this.$emit('cancel')
              }
            })
        }
      } else {
        this.toast('error', 'Invalid Email')
      }
    },
    onClose() {
      //this.$refs.emailForm.reset()
      //this.$refs.emailForm.resetValidation()
      this.$refs.emailForm.reset()
      this.$refs.emailForm.resetValidation()
    },
    close() {
      //this.cancel()
    },
    onOpen() {
      console.log('opening')
      console.log(this.options.patient)
      this.$nextTick(() => (this.chartViewer = true))
    },
  },
}
</script>
